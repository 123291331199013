let db
const getDb = () =>
  new Promise((resolve, reject) => {
    if (db) {
      return resolve(db)
    }

    const openRequest = indexedDB.open('demo', 1)

    openRequest.onerror = (error) => {
      console.error('Error opening demo db', error)
      reject(error)
    }

    openRequest.onsuccess = (event) => {
      db = event.target.result

      db.onerror = (error) => {
        console.error('Demo db error', error)
      }

      resolve(db)
    }

    openRequest.onupgradeneeded = (event) => {
      const db = event.target.result

      db.onerror = (event) => {
        console.error('Error upgrading search db', event)
      }

      if (!db.objectStoreNames.contains('mutations')) {
        db.createObjectStore('mutations')
      }
    }
  })

const getKey = (pathname) => {
  if (/^\/v2\/emma\/client\/\d+\/membertable\/\d+\/member/.test(pathname)) {
    return 'member_id'
  }

  return 'id'
}

export async function addDemoData(res) {
  return res
}

export async function storeDemoData(req) {
  const decoderStream = new TextDecoderStream()
  const decompressionStream = new DecompressionStream('gzip')

  let data = null
  let str = ''
  const writableStream = new WritableStream({
    write(chunk) {
      str += chunk
    },
    close() {
      data = JSON.parse(str)
    }
  })

  await req.body
    .pipeThrough(decompressionStream)
    .pipeThrough(decoderStream)
    .pipeTo(writableStream)

  let id
  let pathname = new URL(req.url).pathname

  if (req.method.toUpperCase() === 'PATCH') {
    const parts = pathname.split('/')
    id = parts.pop()
    pathname = parts.join('/')
  }

  if (pathname.endsWith('/')) {
    pathname = pathname.substring(0, pathname.length - 1)
  }

  if (req.method.toUpperCase() === 'POST') {
    id = crypto
      .randomUUID()
      .replaceAll(/[a-f-]/g, '')
      .substring(0, 11)
  }

  const db = await getDb()
  const key = getKey(pathname)
  const tx = db.transaction('mutations', 'readwrite')
  const store = tx.objectStore('mutations')

  if (!data[key]) {
    data[key] = id
  }

  store.add(data, `${req.method.toLowerCase()}:${pathname}`)

  return new Response(data)
}
